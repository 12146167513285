.web-text {
    text-shadow: 2px 2px 1px #000;
  }

  .btn-left {
    border: solid 1px #ccc;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    width: 200px;
    font-size: 20px;
    font-weight: bolder;
     border-top-right-radius: 20px;
    border-bottom-right-radius: 20px; 

  }

  .btn-left:hover {
    color: white;
    background-color:darkgreen;
  }

  .btn-right {
    /* border: solid 1px #ccc; */
    /* border-top-right-radius: 20px;
    border-bottom-right-radius: 20px; */
    color: white;
    z-index: 9;
  }

  .btn-right:hover {
    background: #fff;
    transition: 0.9s;
  transform: rotateY(180deg);
  }

  .poster {
    background-image: url("../public/assets/video/poster.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  #videoBG {
    position: fixed;
    z-index: -1;
  }

  @media (min-aspect-ratio:16/9) {
    #videoBG {
      width: 100%;
      height: auto;
    }
  }

  #img-logo {
    -webkit-filter: drop-shadow(2px 2px 2px #fff);
    filter: drop-shadow(2px 2px 2px #fff);
  }

  .zero_5 {
    animation-delay: 0.5s;
  }

  .footer-h {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: transparent;
    color: white;
    text-align: center;
    font-size: large;
  }
  .footer-right {
    position: fixed;
    right: 0;
    float: right;
    bottom: 0;
    width: 100%;
    background-color: transparent;
    color: white;
    text-align:right;
    font-size: large;
  }
  .footer-left {
    position: fixed;
    left: 0;
    float: left;
    bottom: 0;
    width: 100%;
    background-color: transparent;
    color: white;
    text-align:left;
    font-size: large;
  }
  .tm {
    top:  155px;
    position: absolute;
    color: #fff;
    font-size: 20px;
  }